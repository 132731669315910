@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);
  
  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");
  $secondary: utils.get-subconfig($zone, "secondary");

  $element: utils.get-subconfig($variants-palette, "element");
  
  .counters-title
  {
    color: mat.m2-get-color-from-palette($zone, "caption");
  }

  .content
  {
    .counter
    {
      background-color: mat.m2-get-color-from-palette($secondary, "bgColor");

      .value
      {
        background-color: mat.m2-get-color-from-palette($element, "bgColor");
        color: mat.m2-get-color-from-palette($secondary, "text");
      }
    }
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);
  
  .counters-title
  {
    font-size: mat.m2-font-size($typography-config, "subtitle-1");
  }

  .content
  {
    .counter
    {
      .title
      {
        @include mat.m2-typography-level($typography-config, "headline-6");
      }

      .value
      {
        span
        {
          @include mat.m2-typography-level($typography-config, "headline-3");
        }
      }
    }
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}