@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");
  $secondary: utils.get-subconfig($zone, "secondary");
  $element: utils.get-subconfig($variants-palette, "element");
  $button: utils.get-subconfig($element, "button");
    
  .title
  {
    color: mat.m2-get-color-from-palette($zone, "caption");
  }
  .form-container{
    background: mat.m2-get-color-from-palette($secondary, "bgColor");
    
    .form-actions .mdc-button.mat-primary {
      background: mat.m2-get-color-from-palette($button, "bgColor");
      color: mat.m2-get-color-from-palette($button, "text");
    } 
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  $typography-variants: map.get($typography-config, "variants");

  .title
  {
    @include mat.m2-typography-level($typography-variants, "form-title");
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}