@use "sass:map";
@use "@angular/material" as mat;

@use "../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $primary: utils.get-pathedconfig($variants-palette, "zone.primary");

  $element: utils.get-subconfig($variants-palette, "element");

  $title: utils.get-pathedconfig($variants-palette, "element.title");

  .content-line
  {
    background-color: mat.m2-get-color-from-palette($primary, "bgColor");
  }

  .title-row
  {
    background-color: mat.m2-get-color-from-palette($title, "bgColor");
    color: mat.m2-get-color-from-palette($title, "text");
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  .font-content
  {
    font-size: mat.m2-font-size($typography-config, "body-2");
  }
  .title-row{
    font-size: mat.m2-font-size($typography-config, "headline-5");
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}