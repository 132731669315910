@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $primary: utils.get-subconfig($zone, "primary");

  $secondary: utils.get-subconfig($zone, "secondary");

  $element: utils.get-subconfig($variants-palette, "element");

  $button: utils.get-pathedconfig($variants-palette, "element.button");

  mat-panel-title.mat-expansion-panel-header-title
  {
    color: mat.m2-get-color-from-palette($primary, "text");
  }

  .mat-expansion-panel-content
  {
    background: mat.m2-get-color-from-palette($secondary, "bgColor");

    mat-label
    {
      color: mat.m2-get-color-from-palette($element, "text");
    }

    button
    {
      background: mat.m2-get-color-from-palette($button, "bgColor");

      .mdc-button__label
      {
        color: mat.m2-get-color-from-palette($button, "text");
      }
    }
  }

}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  mat-panel-title
  {
    @include mat.m2-typography-level($typography-variants, "sub-title");
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}