@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants")) {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");
  $element: utils.get-subconfig($variants-palette, "element");
  $button: utils.get-subconfig($element, "button");
  $button-text-color:  mat.m2-get-color-from-palette($button, "text");


  .autocomplete-chip-list {
    .mdc-evolution-chip-set .mdc-evolution-chip {
      background-color: mat.m2-get-color-from-palette($button, "bgColor");
    }
  
    .mat-mdc-standard-chip {
      color: $button-text-color;
      --mdc-chip-label-text-color:$button-text-color;
      --mdc-chip-with-trailing-icon-trailing-icon-color: $button-text-color;
    }
  }
}

@mixin typography($theme) {

}

@mixin theme($theme) {
  @if map.has-key($theme, color) {
    @include color($theme);
  }

  @if map.has-key($theme, typography) {
    @include typography($theme);
  }
}