@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants")) {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");
  $zone: utils.get-subconfig($variants-palette, "zone");
  $secondary: utils.get-subconfig($zone, "secondary");
  $element: utils.get-subconfig($variants-palette, "element");
  $button: utils.get-subconfig($element, "button");
  $field: utils.get-subconfig($element, "field");


  .form-container,
  .wrap-form {
    background-color: mat.m2-get-color-from-palette($secondary, "bgColor");

    .lib-field-side .mdc-text-field--filled {
      background-color: white;
    }
  }

  .chip-list {
    .single-select.mat-mdc-standard-chip {
      background-color: mat.m2-get-color-from-palette($element, "bgColor");
      color: mat.m2-get-color-from-palette($element, "text");

      &.mdc-evolution-chip--selected {
        background-color: mat.m2-get-color-from-palette($button, "bgColor");

        .mdc-evolution-chip__text-label {
          color: mat.m2-get-color-from-palette($button, "text");
        }
      }
    }
  }

  .form-actions .mdc-button.mat-primary {
    background: mat.m2-get-color-from-palette($button, "bgColor");
    color: mat.m2-get-color-from-palette($button, "text");
  }

  .title {
    color: mat.m2-get-color-from-palette($zone, "caption");
  }

    .view .mdc-text-field--filled.mdc-text-field--disabled
    {
      .mdc-text-field__input,
      .mdc-floating-label,
      .mat-mdc-select-disabled
      {
        color: mat.m2-get-color-from-palette($field, "text");
      }
    }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @if map.has-key($theme, color) {
    @include color($theme);
  }

  @if map.has-key($theme, typography) {
    @include typography($theme);
  }
}