@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");
  $secondary: utils.get-subconfig($zone, "secondary");
  $primary: utils.get-subconfig($zone, "primary");
  $element: utils.get-subconfig($variants-palette, "element");
  $accent: utils.get-pathedconfig($variants-palette, "element.accent");
  $button: utils.get-pathedconfig($variants-palette, "element.button");
  $switch: utils.get-pathedconfig($variants-palette, "element.switch.active");
  $protocol: utils.get-pathedconfig($variants-palette, "element.protocol");

  .eps-protocol
  {
    background-color: mat.m2-get-color-from-palette($zone, "bgColor");

    .title-div
    {
      color: mat.m2-get-color-from-palette($primary, "text");
    }

    .mat-button-toggle-checked.mat-button-toggle-appearance-standard
    {
      background-color: mat.m2-get-color-from-palette($switch, "bgColor");

      color: mat.m2-get-color-from-palette($switch, "text");
    }

    .div-details
    {
      background-color: mat.m2-get-color-from-palette($protocol, "bgColor");
    }

    .mat-mdc-text-field-wrapper
    {
      background-color: mat.m2-get-color-from-palette($zone, "bgColor");
    }

    .mat-mdc-form-field--enabled .mat-mdc-floating-label.mdc-floating-label
    {
      color: mat.m2-get-color-from-palette($element, "text");
    }

    .mdc-text-field--disabled .mdc-floating-label
    {
      color: mat.m2-get-color-from-palette($element, "disabled");
    }

    .title
    {
      background-color: mat.m2-get-color-from-palette($accent, "bgColor");

      .title-txt
      {
        color: mat.m2-get-color-from-palette($accent, "text");
      }
    }

    .body
    {
      background-color: mat.m2-get-color-from-palette($secondary, "bgColor");
    }

    .mat-mdc-standard-chip.mdc-evolution-chip--selected
    {
      background-color: mat.m2-get-color-from-palette($accent, "bgColor");
    }
        

    .send-div
    {
      button
      {
        background-color: mat.m2-get-color-from-palette($button, "bgColor");
        color: mat.m2-get-color-from-palette($button, "text");
      }
    }
  }

  .file
  {
    .file-img
    {
      color: mat.m2-get-color-from-palette($accent, "bgColor");
    }

    .blue
    {
      background-color: mat.m2-get-color-from-palette($button, "bgColor");
    }
  }

  .camera
  {
    .take-image-btn
    {
      background: mat.m2-get-color-from-palette($button, "bgColor");
      color: mat.m2-get-color-from-palette($button, "text");
    }

    .cancel-btn
    {
      color: mat.m2-get-color-from-palette($primary, "text");
      background: mat.m2-get-color-from-palette($button, "text");
    }
  }

  .camera-permissions-message-component
  {
    span
    {
      color: mat.m2-get-color-from-palette($primary, "text");
    }

    button
    {
      color: mat.m2-get-color-from-palette($button, "text");
      background: mat.m2-get-color-from-palette($button, "bgColor");
    }
  }

  .upload-file
  {
    span
    {
      color: mat.m2-get-color-from-palette($accent, "bgColor");
    }

    .img-btn
    {
      color: mat.m2-get-color-from-palette($button, "bgColor");
    }
  }

}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  .eps-protocol
  {
    .title-div
    {
      @include mat.m2-typography-level($typography-variants, "protocol-title");
    }

    .div-details
    {

      .mat-mdc-form-field,
      .mat-mdc-floating-label,
      .mat-mdc-select,
      .mat-button-toggle
      {
        @include mat.m2-typography-level($typography-variants, "protocol-txt");
      }
    }


    .send
    {
      @include mat.m2-typography-level($typography-variants, "protocol-title");
    }
  }

  .camera-permissions-message-component
  {
    span
    {
      @include mat.m2-typography-level($typography-variants, "permission-message-txt");
    }
  }

  .upload-file
  {
    span
    {
      font-size: mat.m2-font-size($typography-config, "subtitle-1");
    }

    .img-div
    {
      span
      {
        font-size: mat.m2-font-size($typography-config, "headline-6");
      }
    }
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}