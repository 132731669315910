@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  // $button: utils.get-pathedconfig($variants-palette, "element.button");

  // mat-panel-title.mat-expansion-panel-header-title
  // {
  //   color: mat.get-color-from-palette($primary, "text");
  // }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  lib-details
  {
    @include mat.m2-typography-level($typography-variants, "user-profile");
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}