@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);
  
  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");
  $secondary: utils.get-subconfig($zone, "secondary");

  $accent: utils.get-pathedconfig($variants-palette, "element.accent");
  
  .modules
  {
    .title
    {
      background-color: mat.m2-get-color-from-palette($accent, "bgColor");
      color: mat.m2-get-color-from-palette($accent, "text");
    }

    .content-and-triangular
    {
      background-color: mat.m2-get-color-from-palette($secondary, "bgColor");
      .content
      {
        .module
        {
          span
          {
            color: mat.m2-get-color-from-palette($secondary, "text");
          }
        }
      }
    }
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);
  
  .modules
  {
    .title
    {
        @include mat.m2-typography-level($typography-config, "headline-5");
    }

    .content-and-triangular
    {
      .content
      {
        .module
        {
          span
          {
            @include mat.m2-typography-level($typography-config, "headline-6");
          }
        }
      }
    }
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}