@use "sass:map";
@use "@angular/material" as mat;

@use "../../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");
  $tab: utils.get-subconfig($element, "tab");
  $active: utils.get-subconfig($tab, "active");
  $passive: utils.get-subconfig($tab, "passive");

  .main-tab-group
  {
    .tab-label
    {
      background-color: mat.m2-get-color-from-palette($tab, "bgColor");
      // border: 1px solid mat.get-color-from-palette($tab, "border");

      .mdc-tab__text-label
      {
        color: mat.m2-get-color-from-palette($tab, "text");
      }
    }

    // .tab-label.passive
    // {
    //   background-color: mat.get-color-from-palette($passive, "bgColor");
    //   .mdc-tab__text-label
    //   {
    //     color: mat.get-color-from-palette($passive, "text");
    //   }
    // }

    .mdc-tab-indicator__content .mdc-tab-indicator__content--underline
    {
      border-color: mat.m2-get-color-from-palette($active, "border");
    }

    .mdc-tab--active
    {

      .mdc-tab-indicator,
      .mdc-tab .mat-mdc-tab .mat-mdc-focus-indicator
      {
        background-color: mat.m2-get-color-from-palette($active, "bgColor");
      }

      .mdc-tab__content .mdc-tab__text-label
      {
        color: mat.m2-get-color-from-palette($active, "text");
      }
    }
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  .mdc-tab__text-label
  {
    @include mat.m2-typography-level($typography-variants, "user-profile");
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}