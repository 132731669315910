@use "sass:map";
@use "@angular/material" as mat;

@use "../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");

  $message: utils.get-subconfig($element, "message");
  $success: utils.get-subconfig($message, "success");
  $error: utils.get-subconfig($message, "error");
  $confirm: utils.get-subconfig($message, "confirm");



  .message
  {

    &.error
    {
      background: mat.m2-get-color-from-palette($error, "bgColor");
      border-color: mat.m2-get-color-from-palette($error, "border");
      color: mat.m2-get-color-from-palette($error, "text");
    }

    &.success
    {
      background: mat.m2-get-color-from-palette($success, "bgColor");
      border-color: mat.m2-get-color-from-palette($success, "border");
      color: mat.m2-get-color-from-palette($success, "text");
    }

    &.confirm
    {
      background: mat.m2-get-color-from-palette($confirm, "bgColor");
      border-color: mat.m2-get-color-from-palette($confirm, "border");
      color: mat.m2-get-color-from-palette($confirm, "text");
    }
  }


}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  .mat-mdc-header-cell,
  .mdc-data-table__cell
  {
    font-size: mat.m2-font-size($typography-config, "body-1");
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}