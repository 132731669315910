@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");

  $footer: utils.get-pathedconfig($variants-palette, "element.footer");

  .footer
  {
    background: mat.m2-get-color-from-palette($footer, "bgColor");

    > div
    {
      color: mat.m2-get-color-from-palette($footer, "text");
    }
  }

}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  .footer > div
  {
    @include mat.m2-typography-level($typography-variants, "footer");
  }

  @media screen and (max-width: 600px)
  {
    .footer > div
    {
      @include mat.m2-typography-level($typography-variants, "footer-mobile");
    }
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}