@use "sass:map";
@use "@angular/material" as mat;

@use "../../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");
  $badge: utils.get-subconfig($element, "badge");
  $info: utils.get-subconfig($badge, "info");
  $warn: utils.get-subconfig($badge, "warn");
  $emergency: utils.get-subconfig($badge, "emergency");

  $secondary: utils.get-subconfig($zone, "secondary");
  $table: utils.get-subconfig($zone, "table");
  $header: utils.get-subconfig($table, "header");
  $alternating: utils.get-subconfig($table, "alternating");
  $warning: utils.get-subconfig($table, "warning");
  $danger: utils.get-subconfig($table, "danger");

  $button: utils.get-subconfig($element, "button");
  $edit: utils.get-subconfig($button, "edit");
  $view: utils.get-subconfig($button, "view");
  $approve: utils.get-subconfig($button, "approve");
  $decline: utils.get-subconfig($button, "decline");


  lib-table
  {

    .mat-mdc-table thead,
    .mat-mdc-header-row,
    .mat-mdc-table .mat-mdc-header-cell
    {
      background: mat.m2-get-color-from-palette($header, "bgColor");
      color: mat.m2-get-color-from-palette($header, "text");
    }

    tr:nth-child(odd of :not(.detail-row))
    {
      background: mat.m2-get-color-from-palette($alternating, "bgColor");
    }

    tr:first-child
    {
      td
      {
        border-top: 2px solid mat.m2-get-color-from-palette($header, "text");
      }
    }

    .warning tr,
    .warning td
    {
      background: mat.m2-get-color-from-palette($warning, "bgColor");
    }

    .emergency tr,
    .emergency td
    {
      background: mat.m2-get-color-from-palette($danger, "bgColor");
    }


    .link-cell
    {
      color: mat.m2-get-color-from-palette($info, "text");
    }

    .page-number
    {
      background: mat.m2-get-color-from-palette($zone, "bgColor");
    }
  }

  .badge-cell
  {
    .warning
    {
      background: mat.m2-get-color-from-palette($warn, "bgColor");
      color: mat.m2-get-color-from-palette($warn, "text");
    }

    .emergency
    {
      background: mat.m2-get-color-from-palette($emergency, "bgColor");
      color: mat.m2-get-color-from-palette($emergency, "text");
    }
  }

  td.expanded
  {
    background: mat.m2-get-color-from-palette($secondary, "bgColor");
  }

  .detail-row-content
  {
    background: mat.m2-get-color-from-palette($zone, "bgColor");
  }

  button.mat-mdc-mini-fab.view
  {
    background: mat.m2-get-color-from-palette($view, "bgColor");
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);
  $typography-variants: map.get($typography-config, "variants");

  .mat-mdc-option .mat-mdc-paginator .navigator-label,
  .mat-mdc-paginator-range-actions,
  .mat-mdc-paginator-range-actions button,
  .mat-mdc-option .mdc-list-item__primary-text
  {
    font-size: mat.m2-font-size($typography-config, "caption");
    font-family: mat.m2-font-family($typography-config, "caption");
  }

  .mat-mdc-header-cell,
  .mdc-data-table__cell,
  .btn-label
  {
    font-size: mat.m2-font-size($typography-config, "body-1");
  }

  .detail-name
  {
    @include mat.m2-typography-level($typography-variants, "table-detail-title");
  }

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}