@use "sass:map";
@use "@angular/material" as mat;

@use "../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme color configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");

  .tile
  {
    background-color: white;
    box-shadow: 0px 3px 17px rgba(mat.m2-get-color-from-palette($element, "shadow"), 0.14);

    span
    {
      color: mat.m2-get-color-from-palette($zone, "caption");
    }
  }
}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);
  $typography-variants: map.get($typography-config, "variants");

  .tile
  {
    span
    {
      @include mat.m2-typography-level($typography-variants, "main-header");
    }
  }
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}