@use "sass:map";
@use "@angular/material" as mat;

@use "../../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");

  .blue-line
  {
    background-color: mat.m2-get-color-from-palette($zone, "border");
  }

}

@mixin typography($theme)
{
  $typography-config: mat.m2-get-typography-config($theme);

}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}