@use "sass:map";
@use "@angular/material" as mat;

@use "../../../../themes/utils";

@mixin color($theme)
{
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  @if (not map.has-key($color-config, "variants"))
  {
    @error 'Angular Material theme configuration is missing a "variants" section';
  }

  $variants-palette: map.get($color-config, "variants");

  $zone: utils.get-subconfig($variants-palette, "zone");

  $element: utils.get-subconfig($variants-palette, "element");
  $detail: utils.get-subconfig($element, "detail");
  $card: utils.get-subconfig($detail, "card");
  $value: utils.get-subconfig($detail, "value");

  .details
  {
    background-color: mat.m2-get-color-from-palette($card, "bgColor");

    .title
    {
      color: mat.m2-get-color-from-palette($value, "text");
    }

    .detail
    {
      background-color: mat.m2-get-color-from-palette($value, "bgColor");
      border-bottom: mat.m2-get-color-from-palette($value, "border") 1px solid;
    }
  }
}

@mixin typography($theme)
{

  $typography-config: mat.m2-get-typography-config($theme);

  @if (not map.has-key($typography-config, "variants"))
  {
    @error 'Angular Material theme typography configuration is missing a "variants" section';
  }

  $typography-variants: map.get($typography-config, "variants");

  .detail,
  .details > .title
  {
    @include mat.m2-typography-level($typography-variants, "protocol-txt");
  }
  .details   .detail-label{
    @include mat.m2-typography-level($typography-variants, "detail-label");}
}

@mixin theme($theme)
{
  @if map.has-key($theme, color)
  {
    @include color($theme);
  }

  @if map.has-key($theme, typography)
  {
    @include typography($theme);
  }
}