// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in the app.
@use "./app/user-desktop";
@use "./app/header";
@use "./app/footer";
@use "./app/protocol";
@use "./app/message";
@use "./app/student";
@use "./app/committee";
@use "./app/intervention";


@use "./app/employee";
@use "./app/activity";
@use "./app/blocked";
@use "./app/unregistered";
@use "./app/shared";

// Import the default theme
@use "./themes/light";
@use "./themes/typography/config" as typography;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.strong-focus-indicators();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// src/material/core/theming/_all-theme.scss
@include mat.all-component-themes(light.$Client-theme);
@include mat.strong-focus-indicators-theme(light.$Client-theme);
// @include mat.core-theme($my-app-theme);
// @include mat.button-theme($my-app-theme);
@include user-desktop.theme(light.$Client-theme);
@include header.theme(light.$Client-theme);
@include footer.theme(light.$Client-theme);
@include message.theme(light.$Client-theme);
@include protocol.theme(light.$Client-theme);
@include shared.theme(light.$Client-theme);
@include student.theme(light.$Client-theme);
@include employee.theme(light.$Client-theme);
@include activity.theme(light.$Client-theme);
@include blocked.theme(light.$Client-theme);
@include unregistered.theme(light.$Client-theme);
@include intervention.theme(light.$Client-theme);

@include mat.typography-hierarchy(typography.$primary-typography);


@font-face
{
  font-family: 'Blender';
  src: url('./assets/fonts/blender.light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face
{
  font-family: 'Blender';
  src: url('./assets/fonts/blender.regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face
{
  font-family: 'Blender';
  src: url('./assets/fonts/blender.bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face
{
  font-family: 'Blender';
  src: url('./assets/fonts/blender.black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

html,
body
{
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body
{
  --orange-color: #fbb02a;
  --blue-color: #0172BB;
  --gray-color: #7f7f7f;
}

.mat-mdc-table
{
  width: 100%;
  max-height: 500px;
  overflow: auto;
}

.mat-mdc-tab-body-wrapper
{
  height: fit-content;
}

.container mat-spinner
{
  width: 100px;
  position: fixed;
  height: 100px;
  left: calc(50% - 50px);
  top: calc(50vh - 50px);
}

.container .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic
{
  stroke: var(--blue-color);
}

.mat-column-Date  div
{
  overflow: visible;
}